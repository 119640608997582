module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"aeb147c4471304e2edef63fad6c98484"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"repcontact","fetchLinks":[],"schemas":{"issue":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Title"}},"subissues":{"type":"Group","config":{"fields":{"subissue":{"type":"Link","config":{"select":"document","customtypes":["subissue"],"label":"Subissue"}}},"label":"Subissues"}},"uid":{"type":"UID","config":{"label":"Url ID","placeholder":"slug"}}}},"subissue":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Title"}},"description":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Description"}},"talking_point_group":{"type":"Group","config":{"fields":{"talking_point":{"type":"Link","config":{"select":"document","customtypes":["talking_point"],"label":"Talking Point"}}},"label":"Talking Point Group"}},"uid":{"type":"UID","config":{"label":"Url ID","placeholder":"slug"}}}},"talking_point":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Title"}},"body":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Body"}},"uid":"UID","config":{"label":"URL ID","placeholder":"path"}}}},"lang":"*","typePathsFilenamePrefix":"prismic-typepaths---gatsby-source-prismic-test-site"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
